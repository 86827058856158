@import "~factor/src/scss/variables";

.nav-bar {
  & &__container {

    > .logo {
      width: 2.1875rem;
      height: 2.1875rem;
      position: relative;
      border-radius: 50%;
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }
  }

  & &__wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 22/16+rem;

    &._jc-fe {
      justify-content: flex-end;
    }

    &-right {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
  }

  & &__balance {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 10/16+rem;

    &-info {
      padding-right: 14/16+rem;

      h4 {
        margin-bottom: 3/16+rem;
        font-size: 1rem;
        line-height: 19/16+em;
        color: $silver-chalice;
      }
    }

    &-value {
      font-size: 1rem;
      line-height: 19/16+em;
      color: $conflower-blue;
    }
  }
}
