@import '../../../../../scss/variables';

.dashboard-header {

  .select {

    &._opened {
      z-index: 30;
    }

    &:hover {

      .select__wrapper {
        z-index: 30;
      }
    }

    &__list {
      margin: 0;

      &-wrapper {

        .btn-select__button {
          margin: {
            left: auto;
            right: auto;
          }
        }
      }

      &-container {
        overflow: hidden;
        margin: 0 -7/16 + rem;

        &._scrollable {
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            box-shadow: 0 -12/16 + rem 0.5rem 10/16 + rem $alto;
            z-index: 1;
          }
        }
      }
    }

    &__option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        .svg-icon {
          visibility: visible;
        }
      }

      &-label {
        flex-grow: 1;
      }

      .svg-icon {
        margin-right: 13/16 + rem;
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        cursor: pointer;
        transition: fill 0.3s;
        visibility: hidden;

        &:hover {
          fill: #f44336;
        }
      }
    }

    &__search {
      margin: 12/16 + rem auto;
      padding: 0;
    }

    &__input {
      margin-top: 6/16 + rem;
    }

    &._opened {
      min-width: 300/16 + rem;
    }
  }
}
