@import "../../../../../src/scss/variables";

.btn._gray {
  &.btn-reset {
    border: 1/16+rem solid transparent;
    padding: 0 .5rem;
    min-width: 76/16 + rem;
    margin: 0;

    .svg-icon {
      width: 12/16 + rem;
      height: 12/16 + rem;
      margin-right: 0.5rem;
      transition: all .3s;
    }

    &:hover,
    &:focus {
      border: 1/16+rem solid $boulder;

      .svg-icon {
        fill: $boulder;
      }
    }
  }
}
