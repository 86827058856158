@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';
@import './chart-header';
@import './chart-block';
@import './chart-tooltip';
@import './geo-chart';
@import './select';

.sidebar-charts {
	&__item {
		&:not(:last-child) {
			border-bottom: 1/16+rem solid $wild-sand;
		}
	}
}
