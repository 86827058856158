@import "~factor/src/scss/variables";

//Fonts
$fontLight: 'Lato Light', sans-serif;
$fontMedium: 'Lato Medium', sans-serif;
$fontRegular: 'Lato Regular', sans-serif;
$fontBold: 'Lato Bold', sans-serif;

//colors
$dove-gray-1: #646464;
$shamrock: #3fd4ad;
$tree-poppy: #ffa300;

$alto: #D8D8D8;
$nobel: #B5B5B5;
$mine-shaft: #3D3D3D;

$tea-green-2: #DCF4CA;
$tusk: #E8F4CA;

$bitter-sweet: #FF6060;
$mona-lisa: #FF9090;

//
$persimmon: #ff5859;

$lochinvar: #2a8f74;
$turquoise: #31d3ae;
$fountain-blue: #5bc0a5;
$cruise: #b2eede;
$tea-green: #cbf4ca;

$buddha-gold: #d39603;
$sunglow: #ffc732;
$lightning-yellow: #ffbf1c;
$chalky: #f0d9a0;
$cumulus: #ffffda;
$tree-poppy: #ffa300;

$silver: #bdbdbd;

$heliotrope: #ca60ff;

$malibu: #6094ff;
$indigo: #4d5ecc;

//new
$conflower-blue: #6075ff;
$boulder: #787878;
$gray: #818181;
$silver-chalice: #a2a2a2;
$alto: #e0e0e0;
$mercury: #e8e8e8;
$wild-sand: #f4f4f4;
$zircon: #f2f5ff;
$burgundy: #9b3434;

//breakpoints
$xs: 480;
$sm: 768;
$md: 992;
$lg: 1280;
$xlg: 1440;
