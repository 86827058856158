@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";
@import "../../scss/mixins";

.manage {
  position: fixed;
  z-index: 20;
  top: 70/16+rem;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: 1/16+rem solid $wild-sand;
  width: 320/16+rem;
  background: #fff;
  transition: transform linear 0.3s;

  &._hidden {
    transform: translateX(-100%);
  }

  &._hidden & {

    &__container {
      opacity: 0;
      pointer-events: none;
      transition: opacity 0s 0.3s;
    }
  }

  &__arrow {
    @extend %button;
    position: absolute;
    z-index: 2;
    bottom: 12/16+rem;
    right: -1/16+rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30/16+rem;
    height: 30/16+rem;
    background: $melrose;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      background: $indigo;
    }
  }

  &._hidden & {

    &__arrow {
      transition: transform 0.3s 0.3s;
      transform: translateX(30/16+rem);

      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__container {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__title {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1/16+rem solid $alto;
    @include f1-700();
    font-size: 18/16+rem;
    line-height: 22/20+em;
    color: #000;
  }

  &__main {
    flex: 0 1 auto;
    width: 100%;
    overflow: auto;
    min-height: 100%;
  }

  &__row {

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__collapse {
    margin: 0 0 .5rem -1.5rem;

    &._nested {
      margin-left: 0;
    }
  }

  &__comment {
    margin-top: 1.5rem;
    @include f1-500();
    font-size: 1rem;
    line-height: 20/16+em;
    color: #000;

    b {
      @include f1-900();
    }
  }

  &__footer {
    position: relative;
    flex: 1 0 70/16+rem;
    min-height: 70/16+rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1rem 1.5rem;
    background: #fff;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: .5rem;
      right: .5rem;
      height: 1/16+rem;
      background: rgba($silver-chalice,.4);
    }

    & > [class^="btn-"] {

      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }
}
