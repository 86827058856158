@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";
@import "../../scss/variables";

.dashboard-header {
  display: flex;
  align-items: center;

  &__select {
    display: flex;
    align-items: center;
    margin-right: 18/16+rem;
    width: 12.5rem;

    ._icon {
      height: 3/4 + rem;
      width: 3/4 + rem;
      z-index: 1;
      position: absolute;
      right: 2rem;
      cursor: pointer;

      .svg-icon {
        position: absolute;
        top: 0;
        left: 0;
        max-height: 3/4 + rem;
        max-width: 3/4 + rem;
      }
    }
  }

  &__btn {
    border: none;
    background: $wild-sand;
    color: $boulder;

    &:not(:last-child) {
      margin-right: .75rem;
    }
  }

  &__timezone {
    margin-right: 12/16+rem;
  }

  &__group {
    display: flex;
    align-items: center;
    margin-left: 20/16+rem;
    font-size: 1rem;
    line-height: 19/16+em;
    color: $silver-chalice;

    h4 {
      margin-right: 1rem;
    }
  }

  &__create {
    max-width: 132/16+rem;
  }

  &__datespicker {
    display: flex;
    align-items: center;
    margin-left: auto;
    min-width: 0;
  }

  &__timezone-picker {
    margin-right: 0.5rem;
    min-width: 0;

    .timezone-picker {

      &._opened {
        z-index: 5;
        min-width: 240/16+rem;
      }
    }
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 2rem;

  &__header {
    margin-bottom: 4/16+rem;
  }

  &__indicators {
    margin-bottom: 12/16+rem;
  }

  &__charts {
    display: flex;
    align-items: stretch;
    margin-bottom: 1.5rem;
  }

  &__chart {
    flex: 1 1 auto;

    &:not(:last-child) {
      margin-right: .5rem;
    }

    &._line {
      flex: 0 1 44%;
      min-width: 44%;
      max-width: 44%;
    }

    &._pie {
      flex: 0 1 27%;
      min-width: 27%;
      max-width: 27%;
    }
  }

  &__filter {
    margin-bottom: 4/16+rem;
  }

  &__table {

    &-wrapper {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      max-width: 100%;
    }
  }
}
