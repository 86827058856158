@import "mixins";

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  max-width: none;
  min-width: 1280/16+rem;
  min-height: 100vh;
  padding: 86/16+rem 0 1rem;

  @include over-xlg() {

    &._sidebar {
      transition: all 0.3s linear;

      &._opened {
        padding-left: 320/16+rem;
      }
    }
  }

  &__content {
    padding: 0 2rem;
  }

  &_registration {
    align-items: center;
    justify-content: center;
  }

  &._centered {
    display: flex;
    padding: 0;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
