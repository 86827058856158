@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";

$statuses: deleted, draft, expired, paused, pending, running;

.filter {

  &__row {
    display: flex;
    align-items: center;

    &_table {
      padding-left: 0;
      margin-left: -30/16 + rem;

      .filter {
        &__remove {
          margin: 0 6/16 + rem;
        }
      }
      
      .date-picker {
        width: 12rem;
      }

      &._visible {
        position: sticky;
        z-index: 4;
        top: 70px;
        background-color: white;
      }
    }
  }

  &__left {
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;

    .filter__select {
      margin-left: 0.875rem;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 44/16+rem;

    svg {
      margin: 0;
    }

    .sort-th {
      margin-left: .25rem;
      width: .5rem;
    }
  }

  &__selects {
    flex: 1 1 auto;
    display: flex;
    margin-right: .75rem;
  }

  &__select {
    flex: 1 1 25%;
    max-width: 170/16+rem;
    padding-bottom: 24/16+rem;

    &._columns {
      display: flex;
      justify-content: flex-end;
      min-width: 120/16+rem;
      max-width: 120/16+rem;
      padding-top: 1.5rem;
      margin-left: 10/16 + rem;

      .select {

        &._opened {
          width: auto;
          min-width: 210/16+rem;
        }

        &__list {
          max-height: 31rem;
        }

        &__btn {
          padding-right: 28/16 + rem;
        }
      }
    }

    &:not(:last-child) {
      padding-right: 12/16+rem;
    }

    .select {
      width: 100%;

      &__option-label {
        padding-right: 1.75rem;
      }

      &__btn-secondary {
        white-space: nowrap;
      }

      &__btn {
        padding-right: 1.25rem;
      }
    }

    &._campaigns {
      max-width: none;

      .select {

        &._opened {
          width: auto;

          .select__wrapper {
            min-width: 300/16+rem;
          }

          .select__option-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        }
      }
    }

    &._statuses {

      ._with-legend {
        position: relative;

        .select {

          &._opened {
            width: auto;
  
            .select__wrapper {
              min-width: 200/16+rem;
            }
          }
  
          .select__wrapper {
            .select__btn {
              padding-left: 1.2rem;
            }
          }
        }

        .select-legend {
          display: block;
          width: 10/16 + rem;
          height: 10/16 + rem;
          border-radius: 50%;
          position: absolute;
          margin-left: 0.25rem;
          top: 40%;
          z-index: 1;

          @each $status in $statuses {
            .#{$status} {
              content: '';
              display: block;
              position: absolute;
              width: 0.75rem;
              height: 0.75rem;
              right: -0.2rem;
              top: -0.1rem;
              background: {
                position: center center;
                size: contain;
                repeat: no-repeat;
                image: url('../table/statusIcons/#{$status}.svg');
              };
            }
          }
        }
      }
    }

    &._types {

      .select {

        &._opened {
          width: auto;

          .select__wrapper {
            min-width: 220/16+rem;
          }
        }
      }
    }
  }

  &__search {
    align-self: center;
    flex: 1 1 25%;
    position: relative;
  }

  &__btn-group {
    margin-right: 18/16+rem;
  }

  &__selected {
    display: flex;
    align-items: center;
    margin-right: 12/16+rem;
    @include f1-400();
    font-size: 17/16+rem;
    line-height: 21/17+em;
    color: #656565;
  }

  &__remove {
    margin-right: 6/16+rem;
    width: 18/16+rem;
    height: 18/16+rem;
    border-radius: 50%;
    background: $alto;

    &:before,
    &:after {
      width: 10/16+rem;
      height: 1/16+rem;
      background: #fff;
      border-radius: 2/16+rem;
    }
  }

  &__btn {

    &:not(:last-child) {
      margin-right: .5rem;
    }
  }
}
