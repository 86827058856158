.duplicate-dialog {
  &__timezone {
    position: relative;
    z-index: 0;
    flex: 1 0 auto;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 100;
    }

    .timezone-picker__btn {
      padding: 0;
    }
  }
}
