@import "../../../../scss/variables";
@import "../../../../scss/mixins";

$checkboxes-column-width: 30px;
$statuses: budget-is-expiring, budget-is-expiring-red, deleted, draft, expired, paused, pending, running;

.dashboard {
  .dashboard__table {
    padding-bottom: 1rem;

    &._with-checkbox:not(._show-skeleton) {
      margin-left: -$checkboxes-column-width;
      width: calc(100% + #{$checkboxes-column-width});
    }
    
    .table {
      &__header {
        .table__top {
          display: none;
          border-bottom: none;
        }
      }

      &__content-header {

        .table__row {
          position: relative;
          padding-right: 0;
        }
      }

      &:hover {
        .table__content {
          &-header {
            @media screen and (min-width: 900px) {
              .table__row {
                padding-right: 15px;
              }
            }
          }
        }
      }

      td {
        &._checkbox {
          min-width: $checkboxes-column-width;
          max-width: $checkboxes-column-width;
          width: $checkboxes-column-width;

          .td-content {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
          }
        }

        &._id {
          .td-content {
            padding: {
              left: 7/16 + rem;
              right: 7/16 + rem;
            }
          }
        }

        &._campaign-name:not(._clicked) {
          .td-content {
            &-inner {
              display: flex;
              align-items: center;
              overflow: visible;

              .svg-icon {
                width: 100%;
                height: 100%;
                
                &__wrapper {
                  flex: 0 0 1.25rem;
                  width: 20/16 + rem;
                  height: 20/16 + rem;  
                  position: relative;
                  margin-right: 0.5rem;

                  @each $status in $statuses {
                    &.#{$status}:after {
                      content: '';
                      display: block;
                      position: absolute;
                      width: 10/16 + rem;
                      height: 10/16 + rem;
                      right: -0.25rem;
                      bottom: -0.15rem;
                      background: {
                        position: center center;
                        size: contain;
                        repeat: no-repeat;
                        image: url('./table/statusIcons/#{$status}.svg');
                      };
                    }
                  }
                }
              }
            }
          }
        }

        &._editable:not(._clicked) {
          .td-content {
            &-inner {
              overflow: visible;
            }
          }
        }

        &._end-date {
          .td-content {
            .text-field__input {
              padding-left: 5px;
            }
          }
        }

        &._type {
          text-transform: uppercase;
        }

        &.w-40-60 {
          width: 40px;
          min-width: 40px;
          max-width: 80px;
        }

        .checkbox {
          &._dirty {
            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 0.5rem;
              height: 2/16 + rem;
              top: calc(50% - 1px);
              left: calc(50% - 5px);
              background-color: $silver-chalice;
              border-radius: 2px;
            }

            &:hover {
              &:after {
                background-color: $conflower-blue;
              }
            }
          }
        }

        &._orange {
          .td-content {
            color: #FFA500;
          }
        }

        &._red {
          .td-content {
            color: $burgundy;
          }
        }

        &._editable {

          .td-content {
            padding: 0 8/16+rem;

            &._click {
              padding-top: .25rem;
            }

            &-inner {
              width: 100%;
            }

            .editable-text {
              width: 100%;
              height: 23/16+rem;
            }
          }
        }

        &._right {

          .td-content {

            &-inner {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

%text {
  @include f1-400();
  font-size: 14/16 + rem;
  line-height: 17/14 + em;
}

.changing-value {
  height: 45/16 + rem;
  border: 1/16 + rem solid $alto;
  display: flex;
  align-items: center;
  justify-content: center;
  @include f1-400();
  @extend %text;

  &__title {
    text-align: center;
  }

  &__old {
    margin-right: 0.5rem;
    @include flex-width(100);
    text-align: right;
  }

  &__difference {
    margin-right: 0.5rem;
    color: $lochinvar;

    &._minus {
      color: $persimmon;

      &:before {
        content: "-";
        margin-right: 0.5rem;
      }
    }

    &:before {
      content: "+";
      margin-right: 0.5rem;
    }
  }

  &__new {
    display: block;
    margin-left: 0.5rem;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    @include flex-width(100);
    @include f1-700();
    @extend %text;
    color: #000;
  }
}

.changing-date {
  line-height: 1.4;
  height: 60/16 + rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8/16 + rem;
  border: 1/16 + rem solid $alto;
  .new-end-date {
    color: $lochinvar;
    font-weight: 700;
  }
}

.td-campaign-name {
  flex: 1 1 auto;
  min-width: 0;

  &__text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}