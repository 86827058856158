@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";
@import "../../scss/variables";
@import "../../scss/mixins";

%text {
  @include f1-400();
  font-size: 14/16+rem;
  line-height: 17/14+em;
}

.dropdown {
  position: relative;
  z-index: 1;

  &__list-wrapper {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 250px;
    overflow: auto;
  }

  &._opened & {

    &__list-wrapper {
      display: block;
    }
  }

  &__list {
    display: block;
  }

  &__option {
    @extend %button;
    display: block;
  }
}
