.create-group-container {
  &__title {
    text-align: center;
  }

  &__text-field {
    margin: 0 auto 1rem;
  }

  &__button {
    margin-left: auto;
    margin-right: auto;
  }
  
  &__divider {
    margin: 0 -1rem 0.75rem;
    height: 1px;
    background-color:rgb(237, 237, 237);
  }
}
