@import '../../../../../scss/variables';

.chart-block {
  padding: 1rem 0.5rem;

  &__header {
    margin-bottom: 1.5rem;
  }

  &__main {
    height: 200/16 + rem;

    &._ml-24 {
      margin-left: 24/16 + rem;
    }

    svg {
      circle {
        pointer-events: auto !important;

        & ~ text {
          visibility: hidden;
          pointer-events: none;
        }
      }
    }

    &._horizontal {
      height: 216/16 + rem;
    }
  }

  &__g {
    position: relative;
  }

  &__column {
    &:hover {
      fill: #13775e;
    }
  }

  &__footnote {
    color: $silver-chalice;
    font-size: 12/16 + rem;
    margin-left: 4/16 + rem;
    line-height: 1.4;
    text-align: center;
  }

  .recharts-bar-rectangle {
    path:hover {
      fill: rgb(38, 150, 122);
    }
  }
}
