@import "variables";
@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-size: 16px;
}

body {
  margin: 0;
  @include f1-400();
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tabs__btn {
  text-transform: capitalize;
}

.nav-bar {
  z-index: 50;
}

p {
  margin-bottom: 1.5rem;
  font-family: Lato Regular,sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.78947em;
  color: $dove-gray;
}

.no-interaction {
  user-select: none;
  pointer-events: none;
  touch-action: none;
}

::-webkit-scrollbar-button,
::-webkit-scrollbar-corner {
  display: none;
}

.dialog {
  &__content {
    width: 530/16 + rem;

    ._black {
      color: #000000;
    }
  }
}
