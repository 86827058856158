@import '../../../../../scss/variables';

.chart-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	margin-bottom: 20/16+rem;

	&__controls {
		display: flex;
		align-items: center;
		width: 100%;

		&-vs {
			margin: 0 12/16+rem;
			@include f1-300();
			color: #232323;
		}

		&-btn {
			@extend %button;
			cursor: pointer;
			@include f1-400();
			font-size: 1rem;
			line-height: 19/16+em;
			color: #5874DB;
		}
	}

	&__select {
		flex: 1 1 147/16+rem;
		max-width: 147/16+rem;
		min-width: 0;
		position: relative;

		& + .chart-header {

			&__select {
				margin-left: .5rem;
			}
		}

		.select {
			width: 100%;

			&._opened {
				width: auto;
				min-width: 226/16+rem;
			}

			&__option {

				&-label {
					@include f1-400();
				}
			}
		}

		&._with-legend {

			.select {

				&__btn {
					padding-left: 1rem;
				}

				&__label {

					&._up {
						transform: translate(-9/16+rem, -18/16+rem);
					}
				}
			}
		}

		&:last-child:not(:only-child) {
			display: flex;
			justify-content: flex-end;
		}

		.select-legend {
			display: block;
			width: 10/16 + rem;
			height: 10/16 + rem;
			border-radius: 50%;
			position: absolute;
			left: 0.25rem;
			top: calc(50% - .25rem);
			z-index: 1;
		}
	}
}
