@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";
@import "../../../../scss/variables";

.dashboard-indicators {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    border: 1/16+rem solid $alto;
    border-radius: 4/16+rem;
    box-shadow: 0 2/16+rem 4/16+rem 0 rgba($mercury, .5);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1 25%;
    min-width: 25%;
    max-width: 25%;
    min-height: 58/16+rem;
    padding: .5rem 1.5rem;
    border-top: 1/16+rem solid $alto;
    border-right: 1/16+rem solid $alto;

    &:nth-child(4n) {
      border-right: none;
    }

    &:nth-child(-n+4) {
      border-top: none;
    }
  }

  &__value {
    margin-right: 1rem;
    @include f1-700();
    font-size: 18/16+rem;
    line-height: 22/18+em;
    color: #000;
  }

  &__numbers {
    display: flex;
    align-items: center;
  }

  &__name {
    margin-right: 10/16+rem;
    margin-bottom: 0.25rem;
    @include f1-400();
    font-size: 0.875rem;
    line-height: 22/18+em;
    color: $silver-chalice;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__percent {
    display: flex;
    align-items: center;
    @include f1-400();
    font-size: 10/16+rem;
    line-height: 12/10+em;

    &._up {
      color: #3F9B34;
    }

    &._down {
      color: $burgundy;

      svg {
        transform: rotate(180deg);

        polygon,
        path {
          fill: $burgundy;
        }
      }
    }

    svg {
      margin-right: 6/16+rem;
    }
  }
}
