@import "../../../../../../../scss/variables";

.vert-chart-tooltip {
  &.tooltip {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    background-color: #787878;
    border-radius: 4px;
    z-index: 1000;
    transition: all 0.3s;
  }

  &__text {
    color: white;
    margin: 0;
    font-size: 1rem;
    line-height: 20/16 + rem;

    &._dot {
      display: flex;
      align-items: center;

      &:before {
        content: '';
        margin-right: .25rem;
        width: 10/16 + rem;
        height: 10/16 + rem;
        border-radius: 50%;
      }

      &._green {

        &:before {
          background: #28BC97;
        }
      }

      &._blue {

        &:before {
          background: $indigo;
        }
      }
    }
  }

  &:before,
  &:after {
    display: none;
  }
}
      
