@import "~factor/src/scss/variables";
@import "~factor/src/scss/mixins";
@import "../../../../../../../../scss/variables";
@import "../../../../../../../../scss/mixins";

%text {
  @include f1-400();
  font-size: 14/16 + rem;
  line-height: 17/14 + em;
}

.btn-menu {
  position: relative;

  &._opened {
    z-index: 20;
  }

  &__content-wrapper {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 210/16 + rem;
    min-height: 100%;
    padding: 12/16 + rem 1rem 1rem;
    border-radius: .25rem;
    border: 1/16 + rem solid $alto;
    background: #f2f2f2;
    box-shadow: 0 0 12/16 + rem 0 rgba($silver-chalice, 0.5);
    transition: box-shadow 0.25s;
  }

  &._opened & {
    &__content-wrapper {
      display: block;
      border: 1px solid $conflower-blue;
      box-shadow: 0 0 0.1875rem 0 rgba(5, 13, 66, 0.53);
    }
  }

  &__content {
    display: block;
  }

  &__btn {
    padding: 0 1.25rem;

    &.btn-round {

      &._icon-right {

        .btn-round__prefix {
          right: .625rem;
          transform: translate(50%, -50%);

          path {
            fill: $conflower-blue;
            transition: fill .25s;
          }
        }
      }

      &:not(:disabled):hover {

        .btn-round__prefix {

          path {
            fill: $indigo;
          }
        }
      }

      &:disabled:hover {
        cursor: not-allowed;
        box-shadow: none;
      }
    }
  }
}

.btn-menu._groups-actions {
  .btn-menu__content-wrapper {
    width: 300/16 + rem;
    background: white;
  }

  .btn-select {
    position: relative;

    .select__value {
      font-family: 'Lato Bold';
    }

    .select__btn-secondary {
      cursor: pointer;
    }

    .select__indicator {
      transform: rotateX(180deg);
      top: 0.25rem;

      &:after {
        content: '';
        position: absolute;
        width: 20/16 + rem;
        height: 20/16 + rem;
        top: -14/16 + rem;
        left: -10/16 + rem;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      position: relative;
      border-bottom: 1/16 + rem solid #d8d8d8;
      margin: 0 -1rem 1rem;
      padding: {
        left: 1rem;
        right: 42/16 + rem;
        bottom: 0.5rem;
      }
    }

    &__button {
      max-width: 176/16 + rem;
      margin: {
        left: auto;
        right: auto;
      }
    }

    &__item {
      padding: 0 2.5rem 0 2rem;
      margin: 0;
      border-radius: 0;
      height: 2rem;
      position: relative;

      &._active {
        color: $conflower-blue;
        font-weight: 700;
        background-color: #E0E4FF;
      }

      .svg-icon {
        fill: $conflower-blue;
        position: absolute;
        left: 0.5rem;
        transition: fill 0.3s;
        visibility: hidden;


        &:last-of-type {
          fill: $silver-chalice;
          left: auto;
          right: 1rem;

          &:hover {
            fill: #f44336;
          }
        }
      }

      &:hover {
        .svg-icon {
          visibility: visible;
        }
      }
    }

    &__list {
      border: none;
      padding-bottom: 0;
      margin: {
        left: auto;
        right: auto;
        bottom: 0;
      }
      overflow: auto;
      max-height: 10rem;

      &-wrapper {
        margin: 0 -1rem;
        overflow: hidden;
        position: relative;

        &._scrollable {
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 0;
            top: 0;
            left: 0;
            box-shadow: 0 -12/16 + rem 0.5rem 10/16 + rem $alto;
            z-index: 1;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 0;
            bottom: 0;
            left: 0;
            box-shadow: 0 12/16 + rem 0.5rem 10/16 + rem $alto;
            z-index: 1;
          }
        }
      }
    }
  }
}

.btn-select {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__title {
    @extend %button;
    position: relative;
    display: block;
    margin: -12/16 + rem (-16/16) + rem 0;
    padding: 12/16 + rem 32/16 + rem 9/16 + rem 13/16 + rem;
    cursor: pointer;
    @extend %text;
    @include f1-700();
    text-align: left;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
      border-bottom: 5/16 + rem solid $gray;
      border-left: 5/16 + rem solid transparent;
      border-right: 5/16 + rem solid transparent;
    }
  }

  &__search {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -1rem 4/16 + rem;
    padding: 0 7/16 + rem;
  }

  &__list {
    margin: 0 -1rem;
    padding-bottom: 10/16 + rem;
    border-bottom: 1/16 + rem solid $gray;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 13/16 + rem;
    height: 28/16 + rem;
    cursor: pointer;
    @extend %text;
    @include f1-300();

    &:hover {
      background: $zircon;
    }

    &._active {
      padding-right: 9/16 + rem;
      background: #dfe4ff;
    }

    svg {
      width: 1rem;
      height: auto;
    }
  }

  .checkbox {
    &__label {
      @extend %text;
      @include f1-400();
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 -1rem;
    padding-top: 8/16 + rem;

    &:not(:last-child) {
      padding-bottom: 0.5rem;
      border-bottom: 1/16 + rem solid $gray;
    }

    &-title {
      margin-bottom: 10/16 + rem;
      padding: 0 13/16 + rem;
      @extend %text;
      @include f1-700();
    }
  }

  &__btn {
    @extend %button;
    display: flex;
    align-items: center;
    padding: 0 13/16 + rem;
    cursor: pointer;
    @extend %text;
    @include f1-300();
    color: #000;
    line-height: 26/16 + rem;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:not(:disabled):hover {
      background: #cbcbcb;
    }

    &:disabled {
      cursor: not-allowed;
      color: $boulder;
    }
  }

  &__icon {
    margin-right: 10/16+rem;
    width: .75rem;
    line-height: 0;

    svg {
      width: 100%;
      height: auto;
    }

    path {
      fill: $dove-gray;
    }
  }
}
