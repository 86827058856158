.toast-message {
  &__container {
    display: block;
  }

  &__message {
    margin: 0;
    color: #ffffff;
    font-size: 1rem;
  }
}
